import {inject, Injectable, signal} from '@angular/core';
import {Observable, BehaviorSubject, Subject} from 'rxjs';
import {debounceTime, filter, map} from 'rxjs/operators';
import {Router, NavigationEnd} from '@angular/router';
import {CommonUtilitiesService, urlWithoutParams} from 'utilities';
import {IGpsPosition, IIBricksEntity, INavItem, IProfile, IQaDocument, IResponse, ITask} from '../interfaces';
import {ValueEnum} from '../enums/key.enum';
import {RouteEnum} from '../enums/route.enum';
import {getTaskParentOriginUrl} from '../utilities';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  private readonly commonUtilitiesService = inject(CommonUtilitiesService);
  private readonly entitySubject = new BehaviorSubject<IResponse<IIBricksEntity>>(null);
  public readonly getSidenavItemsSubject = new Subject<IProfile>();
  public readonly gpsPositionSubject = new Subject<IGpsPosition>();
  public readonly editModeSubject = new Subject<boolean>();
  public readonly translateChangeSubject = new Subject<void>();
  public readonly twoFactorAuthSubject = new Subject<void>();
  public readonly getDataSubject = new Subject<void>();
  public sidenavOpened: boolean;
  public currentRoute = signal<INavItem>(null);

  constructor(private router: Router) {
    this.sidenavOpened = !this.isMobile;
  }

  get isMobile(): boolean {
    return this.commonUtilitiesService.isMobile;
  }

  public getLayout(): Observable<boolean> {
    return this.commonUtilitiesService.getLayout();
  }

  public getWindowResizeWithDebounce(): Observable<number> {
    return this.commonUtilitiesService.getWindowResize().pipe(debounceTime(300));
  }

  public getSidenavItems(): Observable<IProfile> {
    return this.getSidenavItemsSubject.asObservable();
  }

  public getEditMode(): Observable<boolean> {
    return this.editModeSubject.asObservable();
  }

  public getNavigationEnd(): Observable<NavigationEnd> {
    return this.router.events.pipe(filter((e: NavigationEnd) => e instanceof NavigationEnd));
  }

  public getGpsPosition(): Observable<IGpsPosition> {
    return this.gpsPositionSubject.asObservable();
  }

  public getTranslation(): Observable<void> {
    return this.translateChangeSubject.asObservable();
  }

  public getEntity(): Observable<IIBricksEntity> {
    return this.getResponseEntity().pipe(map(res => res?.data));
  }

  public getResponseEntity(): Observable<IResponse<IIBricksEntity>> {
    return this.entitySubject.asObservable();
  }

  public setEntity(res: IResponse<IIBricksEntity>): void {
    this.entitySubject.next(res);
  }

  public get2FA(): Observable<void> {
    return this.twoFactorAuthSubject.asObservable();
  }

  public getData(): Observable<void> {
    return this.getDataSubject.asObservable();
  }

  public shareGpsPosition(): void {
    if (navigator?.geolocation) {
      navigator.geolocation.getCurrentPosition(pos => {
        this.gpsPositionSubject.next({
          lat: pos.coords.latitude,
          lon: pos.coords.longitude,
          altitude: pos.coords.altitude
        });
      });
    }
  }

  get entity(): IIBricksEntity {
    return this.entitySubject.value.data;
  }

  get documentUrl(): string {
    const origin = location.origin.includes(ValueEnum.Localhost) ? 'https://test.isend.no' : location.origin;
    let url = this.router.url;

    if (url.includes(RouteEnum.Tasks)) {
      const task = this.entity as ITask;
      const entityUrl = getTaskParentOriginUrl(task);
      if (entityUrl) {
        url = `/${entityUrl.url}/${entityUrl.id}`;
      }
    } else if (url.includes(RouteEnum.QaDocuments)) {
      const qaDoc = this.entity as IQaDocument;
      url = url.replace(`/${RouteEnum.Management}`, '');
      url = url.replace(qaDoc.unid, qaDoc.docNum);
    }

    return origin + urlWithoutParams(url);
  }
}
